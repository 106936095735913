import React from "react"
// import { Link } from "gatsby"
import Gallery from "react-grid-gallery"
import Layout from "../components/layout"
import SEO from "../components/seo"

class EarthGalleryPage extends React.Component {
  render() {
    function rightClick(e) {
      e.preventDefault()
    }
    return (
      <Layout location={this.props.location} title="Earth Gallery">
        <SEO title="The Astronaut's View of Earth Gallery" />
        <section id="earthgallery-page" className="page">
          <div className="container text-white">
            <div
              className="content bg-black p-5"
              onContextMenu={e => rightClick(e)}
            >
              <p>
                <h3>Every second of every day </h3> there is a picture of the
                illuminated hemisphere of our planet. You have to be at least
                250,000 miles away from Home to see it! Our Collection of Earth
                portraits are taken by a satellite based camera flying nearly 1
                Million miles away from the Earth's surface.
              </p>

              <div className="row">
                <div
                  style={{
                    display: "block",
                    minHeight: "1px",
                    width: "100%",
                    overflow: "auto",
                    textAlign: "center",
                  }}
                >
                  <Gallery className="circle-img" images={IMAGES} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default EarthGalleryPage

const IMAGES = [
  {
    src:
      "/assets/img/0D3DCEE1-1314-4CF4-B61A-54925D51FCEA-4750-00000B205D0465BD.jpg",
    thumbnail:
      "/assets/img/0D3DCEE1-1314-4CF4-B61A-54925D51FCEA-4750-00000B205D0465BD.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/3E137E5F-F907-4531-A897-62AE7A96887C-4750-00000B1ECE46AC78.jpg",
    thumbnail:
      "/assets/img/3E137E5F-F907-4531-A897-62AE7A96887C-4750-00000B1ECE46AC78.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/09BEAB24-D646-4CFF-A3F4-3C4C43CF44F8-4750-00000B1DA74F657B.jpg",
    thumbnail:
      "/assets/img/09BEAB24-D646-4CFF-A3F4-3C4C43CF44F8-4750-00000B1DA74F657B.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/20CB5EB4-A3F7-438F-906B-36BD284FC398-4750-00000B1F9DF4DE29.jpg",
    thumbnail:
      "/assets/img/20CB5EB4-A3F7-438F-906B-36BD284FC398-4750-00000B1F9DF4DE29.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/385D57D3-CE6A-4AF9-9109-B09EE449D7B4-4750-00000B1D7ACE078F.jpg",
    thumbnail:
      "/assets/img/385D57D3-CE6A-4AF9-9109-B09EE449D7B4-4750-00000B1D7ACE078F.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/1672AC33-F26B-493A-93B9-1D09A2137E22-4750-00000B1E06817D7C.jpg",
    thumbnail:
      "/assets/img/1672AC33-F26B-493A-93B9-1D09A2137E22-4750-00000B1E06817D7C.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/5890C9B0-5449-4691-A462-948F8EC566D6-4750-00000B1F052EB9F0.jpg",
    thumbnail:
      "/assets/img/5890C9B0-5449-4691-A462-948F8EC566D6-4750-00000B1F052EB9F0.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/0528469E-C527-4393-AE62-2032E76A36F4-4750-00000B1F6AAC1B2A.jpg",
    thumbnail:
      "/assets/img/0528469E-C527-4393-AE62-2032E76A36F4-4750-00000B1F6AAC1B2A.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/Asia-AUS-moon-transit-dscovr-2016.jpg",
    thumbnail: "/assets/img/Asia-AUS-moon-transit-dscovr-2016.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/Asia-AUS-solar-eclipse-dscovr-2016.jpg",
    thumbnail: "/assets/img/Asia-AUS-solar-eclipse-dscovr-2016.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/August-2017-Solar-Eclipse.jpg",
    thumbnail: "/assets/img/August-2017-Solar-Eclipse.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/black-marble-night-lights-2012-nasa.jpg",
    thumbnail: "/assets/img/black-marble-night-lights-2012-nasa.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/blue-marble-composite-2012-NSAmer.jpg",
    thumbnail: "/assets/img/blue-marble-composite-2012-NSAmer.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/Blue-marble-original-apollo17-1972-nasa.jpg",
    thumbnail: "/assets/img/Blue-marble-original-apollo17-1972-nasa.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/CC2A8F45-EB83-4BBD-9B3C-0D9B98375C24-4750-00000B1D30A88587.jpg",
    thumbnail:
      "/assets/img/CC2A8F45-EB83-4BBD-9B3C-0D9B98375C24-4750-00000B1D30A88587.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/darkside-moon-earth-view-2014.jpg",
    thumbnail: "/assets/img/darkside-moon-earth-view-2014.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/E2B2DC75-A4C4-4BFE-89F4-9699E79A4301-4750-00000B1E32D76053.jpg",
    thumbnail:
      "/assets/img/E2B2DC75-A4C4-4BFE-89F4-9699E79A4301-4750-00000B1E32D76053.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/earth-and-moon-halffull-1998.jpg",
    thumbnail: "/assets/img/earth-and-moon-halffull-1998.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/earth-atmosphere-night-scene-ISS.jpg",
    thumbnail: "/assets/img/earth-atmosphere-night-scene-ISS.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/earth-atmosphere-night-scene2-ISS.jpg",
    thumbnail: "/assets/img/earth-atmosphere-night-scene2-ISS.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/earth-northen-night-lights-ISS.jpeg",
    thumbnail: "/assets/img/earth-northen-night-lights-ISS.jpeg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/earthrise-1968-nasa.jpeg",
    thumbnail: "/assets/img/earthrise-1968-nasa.jpeg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/earthrise-2009-nasa.jpeg",
    thumbnail: "/assets/img/earthrise-2009-nasa.jpeg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/earthrise-apollo11-1969.jpeg",
    thumbnail: "/assets/img/earthrise-apollo11-1969.jpeg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/epic_20170207_N_Africa.jpg",
    thumbnail: "/assets/img/epic_20170207_N_Africa.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/F2BB985C-710B-47D8-8A57-88EAACE94523-4750-00000B1EA2C9EB2C.jpg",
    thumbnail:
      "/assets/img/F2BB985C-710B-47D8-8A57-88EAACE94523-4750-00000B1EA2C9EB2C.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },

  {
    src: "/assets/img/first-earth-and-moon-1977-voyager1-nasa.jpeg",
    thumbnail: "/assets/img/first-earth-and-moon-1977-voyager1-nasa.jpeg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/first-earthrise-1966-nasa.jpeg",
    thumbnail: "/assets/img/first-earthrise-1966-nasa.jpeg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/pale-blue-dot-3pt7billion-miles-away.jpeg",
    thumbnail: "/assets/img/pale-blue-dot-3pt7billion-miles-away.jpeg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },

  {
    src: "/assets/img/Circle-Graphics-20x20-Africa-ME-EU-store.jpg",
    thumbnail: "/assets/img/Circle-Graphics-20x20-Africa-ME-EU-store.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/GVF-10x10-DSCOVR-NS-Amer.jpg",
    thumbnail: "/assets/img/GVF-10x10-DSCOVR-NS-Amer.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/Citcle-Grpahics-30x30-North-America-store.jpg",
    thumbnail: "/assets/img/Citcle-Grpahics-30x30-North-America-store.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/Citcle-Grpahics-30x30-Eastern-Hemi-store.jpg",
    thumbnail: "/assets/img/Citcle-Grpahics-30x30-Eastern-Hemi-store.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/Circle-Graphics-48x32-North-America-store.jpg",
    thumbnail: "/assets/img/Circle-Graphics-48x32-North-America-store.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src: "/assets/img/Circle-Graphics-48x32-Eastern-Hemi-store.jpg",
    thumbnail: "/assets/img/Circle-Graphics-48x32-Eastern-Hemi-store.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
  {
    src:
      "/assets/img/Circle-Graphics-24x16-North-America-Moon-Transit-store.jpg",
    thumbnail:
      "/assets/img/Circle-Graphics-24x16-North-America-Moon-Transit-store.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 300,
  },
]
